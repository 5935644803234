import React from 'react';
import { ReviewsMyForm } from '../components';

const MyReview = (props) => {
  return (
    <React.Fragment>
      <ReviewsMyForm />
    </React.Fragment>
  );
};

export default MyReview;
