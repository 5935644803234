import React from 'react';
import { CrewsMyForm } from '../components';

const MyCrew = (props) => {
  return (
    <React.Fragment>
      <CrewsMyForm />
    </React.Fragment>
  );
};

export default MyCrew;
